@media screen and (min-width: 1430px) {
    .container{ width: 1428px;}
}
@media screen and (max-width: 1429px) {
    .intro p {font-size: 29px;line-height: 38px;}
    .btn.btn-default.btn-contact {margin-top: 9px;}
    .header-nav nav ul li {padding: 0 7px;width: 100px;}
    .header-nav nav ul a {font-size: 14px;}
    .header-contact {padding-left: 5px;margin-top: 10px;}
    .header-contact a {font-size: 22px;}
    .header-contact p {font-size: 11px;}
    .our-services-box {padding: 14px 20px;}
    .services-list ul li {padding-left: 36px;}
    .services-list ul li a {font-size: 18px;line-height: 42px;}
    .services-list ul li::after {font-size: 17px;top: 8px;}
    .our-service-info p {font-size: 13px;line-height: 19px;}
    .learn-more-link {font-size: 13px;line-height: 19px;}
    .learn-more-link::after {margin-left: 7px;margin-top: -2px;font-size: 13px;}
    .block-list p {font-size: 13px;line-height: 19px;}
    .block-list .sub-title {font-size: 28px;line-height: 30px;}
    .reviews-logo li {list-style: outside none none;padding-right: 24px;text-align: center;vertical-align: middle;width: 49%;}
    .logo-section .member-logo-border {padding-left: 15px;}
    .member-logo li {list-style: outside none none;padding-right: 18px;text-align: center;width: 32%;}
    .form-section input {width: 254px;}
    .footer-address p {font-size: 13px;}
    .footer-nav ul li::after {margin-right: 1px;}
    .footer-nav ul li a {font-size: 12px;}
    .footer-links p {font-size: 12px;}
    .our-service-info.inner-service-list {padding: 0 0 0 25px;}
    .inner-service-list .inner-title {font-size: 24px;}
    .form-section input[type="submit"] {width: 100%;}
}

@media screen and (max-width: 1199px) {
    .header-contact a {font-size: 19px;}
    .header-right {width: 76%; float: right;display: inline;}
    .header-logo {width: 24%;}
    .header-nav {width: 100%;padding-right: 0;margin: 0;}
    .header-contact {width: 100%;}
    .header-nav nav ul li:last-child {padding-right: 0;}
}

@media (min-width: 992px) and (max-width: 1024px) {
    .header-row {-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
    .header-nav {-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}

    .header-logo {float: left;width: 24%;}
    .header-right {display: inline;float: right;width: 76%;}

}

@media (min-width:992px) and (max-width: 1199px) {
    .header-contact {margin-top: 0;}
    .header-nav {-webkit-box-pack: end;-ms-flex-pack: end;justify-content: flex-end;}
    .btn.btn-default.btn-contact {padding: 13px 5px 9px;font-size: 18px;margin-top: 10px;}
    .btn::after {font-size: 24px;margin-left: 11px;}
    .member-logo li {display: inline-block;list-style: outside none none;padding-right: 0;text-align: center;width: 32%;}
    .reviews-logo li {display: inline-block;list-style: outside none none;padding-right: 11px;text-align: center;width: 48%;}
    .form-section input {width: 187px;}
    .footer-address p {font-size: 10px;}
    .socket-links.footer-payment {margin-top: 0;}

    .services-list {padding-right: 20px;}
    .services-list ul li {padding-top: 7px;padding-bottom: 7px;}
    .services-list ul li a {font-size: 18px;line-height: 26px;}
    .our-services-box {padding: 14px 5px;}
    .our-service-info p {font-size: 12px;line-height: 18px;}

    .our-service-info.inner-service-list {padding: 0;}
    .inner-service-list .services-list li {padding-bottom: 0;padding-top: 0;}
    .inner-service-list .services-list li a {line-height: 30px;}
    .inner-service-list .services-list ul li::after {top: 6px;}

    .intro p {font-size: 23px;line-height: 34px;}

    .site-content {margin-bottom: 0;}
    .footer-section {position: relative;}

}

@media screen and (max-width: 991px) {

    .footer-section {position: relative;}
    .site-content {margin-bottom: 0;}

    .form-section ul li {min-height: inherit;}
    .form-section input[type="email"] + span.wpcf7-not-valid-tip {bottom: -35px;}
    .form-section input[type="tel"] + span.wpcf7-not-valid-tip {bottom: -35px;}
    .form-section .wpcf7-form-control-wrap .wpcf7-not-valid-tip {}
    .form-section .wpcf7-form-control-wrap .wpcf7-not-valid-tip {bottom: -19px;width: auto;text-align: left;}
    .form-section div.wpcf7-response-output {bottom: 25px;}
    .background-white {padding: 30px 15px;}
    .inner-service-list .services-list li {padding-top: 2px;padding-bottom: 2px;}
    .inner-service-list .services-list ul li::after {top: 5px;}
    .inner-service-list .services-list li a {line-height: 25px;}
    .footer-payment {margin-top: 0;}
    .form-section .wpcf7-recaptcha {display: inline-block;height: auto;min-height: inherit;position: relative;top: -10px;vertical-align: middle;}
    .form-section .wpcf7-form-control-wrap {height: 57px;}
    .form-section input[type="submit"] {width: auto;padding: 8px 20px 6px 20px;}

    .form-section .wpcf7-recaptcha {position: relative;left: -27px;}

    .header-nav nav ul {padding-bottom: 30px;}
    .main-navigation {height: 100%;overflow: auto;}
    #nav-toggle {left: 0;top: 19px;padding: 10px 35px 10px 0;}
    .nav-mobile {display: block !important;width: 112px;right: 0;}
    .main-navigation { position: fixed; right: -200px;z-index: 99999;top: 0;height: 100vh;width: 200px; background-color: #d44600; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; padding-top: 25px; }
    .open-menu #nav-toggle {background-color: #d44600;right: 0;width: 112px;}
    #nav-toggle {background-color: #d44600;width: 112px;}
    .open-menu .main-navigation { right: 0;}
    .open-menu .header-menu-icon:after {  content: "\f00d"; }
    .site{  -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; right: 0; }
    .open-menu .site { right: 200px; }

    .menu-item-has-children:hover .sub-menu {background-color: transparent;}
    .header-nav nav ul .current_page_item a {color: #2b2b2b;}
    .footer-nav ul li.current_page_item ul li a {color: #fff;}
    .header-nav nav ul .current_page_item .sub-menu li a {color: #fff;}
    .header-nav nav ul a:hover {color: #2b2b2b;}
    .header-nav nav ul a {padding: 9px 3px;}
    .header-row, .header-nav,.article-block-section .row{ display: block;margin-bottom: 0;}
    .menu-item-has-children .sub-menu {display: block;float: none;padding: 7px 0 7px 15px;}
    .menu-item-has-children:hover .sub-menu {position: relative;}
    .header-nav nav ul li {display: block; width: 100%;text-align: left;padding: 5px 13px;}
    .header-nav nav ul li a {padding: 9px 3px 9px 3px;line-height: 20px;color: #ffffff;}
    .header-nav nav ul li ul li a {padding-left: 30px;}
    .header-nav nav ul li:hover {background-color: transparent;}
    .header-nav nav ul li ul, .header_right nav ul li:hover ul {position: static;border: medium none;padding: 0;background-color: transparent;}
    .header-nav nav ul li a:hover, .header_right nav ul li a:focus {text-decoration: none;}
    .nav-list {display: block !important;}

    .home-slider .item{ height: 305px;}
    .home-slider .owl-item img { opacity: 0;}
    .home-slider {border: 10px solid #ffffff;margin-bottom: 42px;}
    .banner-section .col-sm-3 a {display: block;padding: 0 18px;}

    .header-contact-text {display: none;}
    .header-contact-number {display: none;}
    .mobile-call-text {display: inline;}
    .mobile-call-text {background-color: #454545;background-image: url("..//media/call-text-icon.png");background-position: 11px 6px;background-repeat: no-repeat;color: #ffffff;display: inline-block;font-family: Cooper Hewitt;font-size: 12px;font-weight: bold;max-width: 200px;padding: 5px 15px 3px 2px;text-transform: uppercase;vertical-align: middle;width: 112px;vertical-align: sub;}
    .header-contact {padding-right: 0;position: absolute;right: 0;top: 63px;width: auto;}
    .header-menu-icon:after {color: #ffffff;font-family: fontawesome;content: "\f0c9";font-size: 22px;font-weight: normal;left: 11px;position: absolute;top: 50%;-webkit-transform: translateY(-50%);transform: translateY(-50%);}
    .header-menu-icon {background-color: #d44600;color: #ffffff;font-family: Cooper Hewitt;font-size: 12px;font-weight: bold;padding-left: 35px;text-transform: uppercase;line-height: normal;line-height: 20px;vertical-align: sub;}
    .header-logo {width: 29%;}
    .caption {top: auto;bottom: 0;width: 100%;text-align: left;}
    .caption h1 {font-size: 25px;}
    .banner-section .inner-banner-image .caption {text-align: center;}
    .intro p {font-size: 23px;line-height: 34px;}
    .btn.btn-default.btn-contact {font-size: 13px;padding: 9px 5px 6px;}
    .btn::after {font-size: 19px;margin-left: 9px;margin-top: -1px;}
    .services-list ul li a {font-size: 20px;line-height: 30px;}
    .services-list ul li {padding-bottom: 6px;padding-top: 6px;}
    .services-list {padding-right: 0;}
    .services-list ul li::after {top: 8px;}
    .form-section ul {text-align: center;}
    .form-section ul li:first-child {padding-left: 30px;}
    .form-section ul li {float: none;margin-bottom: 20px;padding-left: 30px;padding-right: 30px;}
    .form-section input {height: 44px;width: 100%;}
    .form-section ul li:last-child {float: none;margin-bottom: 0;padding-left: 0;}
    .small-title {font-size: 16px;}
    .footer-address p {font-size: 11px;line-height: normal;}
    .sub-title {font-size: 24px;}
    .our-service-info.inner-service-list {padding: 0;}
}

@media screen and (min-width: 768px){

    .header-row {-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;display: -webkit-flex; -webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-direction: row;flex-direction: row; flex-direction: -webkit-row;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;justify-content: -webkit-space-between; }
    .header-nav {-webkit-box-align: center;-ms-flex-align: center;align-items: center;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}

    .article-block-section .row { display: -webkit-box; display: -ms-flexbox; display: flex;}

}

@media screen and (max-width: 767px) {

    .hidden-copyright p {display: block;margin-top: 15px;}
    .footer-section {position: relative;}
    .site-content {margin-bottom: 0;}

    .alignleft {display: block;float: none;margin: 0 auto 15px;text-align: center;}
    .aligncenter {display: block;float: none;margin: 0 auto 15px;text-align: center;}
    .alignright {display: block;float: none;margin: 0 auto 15px;text-align: center;}

    .form-section .wpcf7-form-control-wrap .wpcf7-not-valid-tip {width: auto;bottom: -16px;}
    .form-section .wpcf7-recaptcha {-webkit-transform: scale(0.68) !important;left: 8px;}
    .form-section span.wpcf7-not-valid-tip {font-size: 12px;}
    .form-section input[type="email"] + span.wpcf7-not-valid-tip {bottom: -34px;}
    .form-section input[type="tel"] + span.wpcf7-not-valid-tip {bottom: -34px;}

    .banner-section .inner-banner-image .caption {top: 37%;}
    .inner-banner-image {margin-top: -94px;border: 10px solid #ffffff;}
    .container.inner-content-section .row {padding: 0 9px;}
    .inner-content-section .background-white {margin-top: 17px;padding-top: 25px;}
    .inner-content-section .background-white h3 {margin-bottom: 0;margin-top: 28px;font-size: 20px;}
    .background-white p {font-size: 12.5px;line-height: 17.6px;}
    .our-services .right-image {background-image: none !important;}
    .btn-danger {font-size: 12.5px;}
    .btn.btn-danger::after {font-size: 16px;}
    .inner-service-list .inner-title {font-size: 21.5px;}
    .inner-service-list .services-list li a {font-size: 12.5px;}

    .header-contact-text {display: none;}
    .header-contact-number {display: none;}

    .banner-section .inner-banner-image .caption {bottom: 0;padding: 16px 0 6px;top: auto;width: 100%;text-align: center;}
    .inner-banner-image .caption h1 {font-size: 25px;}
    .inner-banner-image img {height: 138px;}
    .background-white {padding: 15px 0;display: block;}
    .btn-danger {width: 100%;min-width: 200px;padding: 9px 0;}
    .inner-service-list .inner-title {margin-top: 33px;}
    .services-list ul {margin-top: 10px;}
    .inner-service-list .services-list li {padding: 1px 10px 1px 22px;}

    .header-menu-icon:after {content: "\f0c9";position: absolute;font-size: 22px;font-weight: normal;position: absolute;left: 11px;top: 5px;font-family: FontAwesome;color: #fff;top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);}

    .mobile-call-text {background-color: #454545;background-image: url("..//media/call-text-icon.png");background-position: 11px 6px;background-repeat: no-repeat;color: #ffffff;font-family: Cooper Hewitt;font-size: 12px;font-weight: bold;padding: 5px 15px 3px 2px;text-transform: uppercase;vertical-align: middle;width: 112px;vertical-align: sub;}
.header-contact {padding-right: 0;position: absolute;right: 0;top: 60px;width: auto;}

.header-menu-icon {background-color: #d44600;
    color: #ffffff;
    font-family: Cooper Hewitt;
    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    padding-left: 35px;
    text-transform: uppercase;
    vertical-align: sub;}

    header.site-header {padding: 20px 0 75px;}
    .header-logo {width: 63%;}

    #nav-toggle {left: 0;padding: 10px 35px 11px 0;top: 19px;}
    .menu-item-has-children .sub-menu {display: block;float: none;padding: 7px 0 7px 15px;}
    .menu-item-has-children:hover .sub-menu {position: relative;}
    .nav-mobile {display: block !important;width: 112px;right: 0;}
    .header-row, .header-nav,.article-block-section .row{ display: block;margin-bottom: 0;}
    .header-nav nav ul li {display: block; width: 100%;text-align: left;padding: 5px 13px;}
    .header-nav nav ul li a {padding: 9px 3px 9px 3px;line-height: 20px;}
    .header-nav nav ul li ul li a {padding-left: 30px;}
    .header-nav nav ul li:hover {background-color: transparent;}
    .header-nav nav ul li ul, .header_right nav ul li:hover ul {position: static;border: medium none;padding: 0;background-color: transparent;}
    .header-nav nav ul li a:hover, .header_right nav ul li a:focus {text-decoration: none;}
    .nav-list {display: block !important;}
    #nav-toggle {background-color: #d44600;width: 100%;}

    .home-slider .item{ height: 300px;}
    .home-slider .owl-item img { opacity: 0;}
    .home-slider {border: 10px solid #ffffff;margin-bottom: 42px;margin-top: -94px;}
    .intro p {padding: 0 8px;text-align: center;}
    .banner-section .col-sm-3 {text-align: center;}
    .banner-section .col-sm-3 a {display: inline-block;padding: 0 18px;text-align: center;}


    .banner-section {padding: 30px 0 37px;}
    .caption .caption-link {font-size: 15px;}
    .caption .caption-link:after {font-size: 16px;right: -18px;}
    .caption {bottom: 0;padding: 20px 18px 16px;text-align: left;top: auto;width: 100%;}
    .caption h1 {font-size: 15px;}
    .intro p {font-size: 21px;line-height: 27px;}
    .banner-section {background-image: none;background-color: #1e1e1e;
	background-image: -webkit-linear-gradient(top, rgba(0,0,0,.42), rgba(0,0,0,0));
	background-image: linear-gradient(to bottom, rgba(0,0,0,.42), rgba(0,0,0,0));}
    .btn.btn-default.btn-contact {float: none;font-size: 16px;margin-top: 14px;padding: 10px 47px;width: auto;}
    .btn:after {font-size: 24px;margin-left: 11px;}
    .sub-title {font-size: 23px;}
    .our-service-info p {font-size: 12.5px;line-height: 17.6px;}
    .services-list ul li {padding: 8px 10px 8px 31px;}
    .services-list ul li a {font-size: 17.5px;line-height: 26px;}
    .services-list ul li:after {font-size: 16px;left: 9px;top: 11px;}
    .our-services {background-image: none !important;padding: 0;}
    .our-service-info {padding-right: 0;padding-top: 0;padding-left: 10px;}
    .services-list {padding-right: 10px;margin-left: -5px;}
    .our-service-info .sub-title {margin-top: 15px;}
    .services-list ul {margin-top: 36px;}
    .our-services-box {margin-top: 29px;padding: 13px 0;margin-bottom: 30px;}
    .our-services-box .col-sm-2 {text-align: center;margin-bottom: 12px;}
    .our-services-box p {display: inline;}
    .our-services-box a {display: inline;}
    .our-services-box .col-sm-2 img {margin-left: 13px;width: 26%;}
    .learn-more-link {font-size: 12.5px;line-height: 17.6px;}
    .learn-more-link:after {top: 0;}
    .block-list p {display: none;}
    .block-list .learn-more-link {display: none;}
    .block-list .sub-title {font-size: 23px;line-height: 19.2px;vertical-align: middle;}
    .article-block-section .col-sm-4 {padding: 0;}
    .block-list {padding: 0;background-color: #d44600;margin-bottom: 15px;text-align: left;}
    .block-list a {display: block;}
    .article-block-section .col-sm-4 .block-list {padding: 28px 35px;}
    .article-block-section .col-sm-4:nth-of-type(1) .block-list {padding-right: 30px;}
    .article-block-section .col-sm-4:nth-of-type(2) .block-list {padding-left: 30px;padding-right: 30px;}
    .article-block-section .col-sm-4:nth-of-type(3) .block-list {padding-left: 30px;}
    .article-block-section {background-color: #fff;padding: 0;}
    .block-list img {display: inline;margin-right: 25px;width: 18%;}
    .block-list h2 {display: inline;}
    .logo-section {padding: 15px 0 50px;text-align: center;}
    .reviews-logo {border-bottom: 1px solid #333333;margin: 32px 10px 45px;padding-bottom: 3px;}
    .reviews-logo li {margin-bottom: 38px;padding-right: 0;display: block;width: auto;}
    .reviews-logo a {display: block;}
    .logo-section .member-logo-border {padding-left: 15px;}
    .member-logo {margin-top: 28px;}
    .member-logo li {margin-bottom: 30px;padding-right: 0;display: block;width: auto;}
    .member-logo a {display: block;}
    .small-title {font-size: 16px;}
    .form-section .small-title {text-align: center;}
    .form-section ul {text-align: center;}
    .form-section ul li:first-child {padding-left: 30px;}
    .form-section ul li {padding-left: 30px;padding-right: 30px;margin-bottom: 20px;float: none;}
    .form-section ul li:last-child {float: none;margin-bottom: 0;}
    .form-section input {width: 100%;height: 44px;}
    .form-section {padding: 17px 0 31px;border-bottom: 1px solid rgba(255, 255, 255, 0.4);}
    .form-section input[type="submit"] {width: auto;padding-top: 9px;}
    .form-section .wpcf7-recaptcha {display: inline-block;height: auto;min-height: inherit;position: relative;left: -27px;top: -10px;vertical-align: middle;}
    .form-section .wpcf7-form-control-wrap {height: 57px;}
    .inner-content-left .wpcf7-recaptcha {transform: scale(0.8);display: inline-block;height: auto;min-height: inherit;position: relative;left: -30px;top: -10px;vertical-align: middle;}
    .inner-content-left .wpcf7-form-control-wrap {height: 57px;}
    .footer-section {text-align: center;}
    .footer-address p {font-size: 11.2px;margin: 0;}
    .footer-section .col-sm-4.text-right {text-align: center;}
    .socket-links li img {width: 32px;}
    .socket-links {margin-top: 12px;}
    .footer-socials {padding: 0 8px;}
    .footer-payment {padding: 0 11px;}
    .footer-links {padding-left: 15px;}
    .mobile-hidden-copyright p {display: none;}
}
