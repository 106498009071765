/*--------------------------------------------------------------
# START HEADER
--------------------------------------------------------------*/

@font-face {
	font-family: 'Cooper Hewitt Book';
	src: url('../fonts/CooperHewitt-Book.eot');
	src: url('../fonts/CooperHewitt-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/CooperHewitt-Book.woff2') format('woff2'),
		url('../fonts/CooperHewitt-Book.woff') format('woff'),
		url('../fonts/CooperHewitt-Book.ttf') format('truetype'),
		url('../fonts/CooperHewitt-Book.svg#CooperHewitt-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt-Light.eot');
    src: url('../fonts/CooperHewitt-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/CooperHewitt-Light.woff2') format('woff2'), url('../fonts/CooperHewitt-Light.woff') format('woff'), url('../fonts/CooperHewitt-Light.ttf') format('truetype'), url('../fonts/CooperHewitt-Light.svg#CooperHewitt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cooper Hewitt';
    src: url('../fonts/CooperHewitt-Bold.eot');
    src: url('../fonts/CooperHewitt-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/CooperHewitt-Bold.woff2') format('woff2'), url('../fonts/CooperHewitt-Bold.woff') format('woff'), url('../fonts/CooperHewitt-Bold.ttf') format('truetype'), url('../fonts/CooperHewitt-Bold.svg#CooperHewitt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

body {color: #262626;font-family: 'Cooper Hewitt';height: 100%;}
.inner-content-left ol, .inner-content-left ul {margin: 0 0 20px;padding: 0 0 0 15px;}
.inner-content-left ol li, .inner-content-left ul li {list-style-position: outside;padding-left: 10px;font-family: Cooper Hewitt Book;}
h1, h2, h3, h4, h5, h6 {font-family: 'Cooper Hewitt Book';font-weight: bold;color: #d44600;}
p {font-family: Cooper Hewitt Book;}
b, strong {font-family: Cooper Hewitt;}

html { min-height:100%; position:relative; }
.site-content {margin-bottom: 207px;}
.footer-section {bottom: 0;left: 0;position: absolute;right: 0;}

.no-padding {padding: 0;}
img {max-width: 100%;height: auto;}
a {color: #d44600;}
a:hover, a:focus {color: #2b2b2b;text-decoration: none;}

.form-section ::-moz-placeholder {color: #000;font-family: Cooper Hewitt Book;}
.form-section ::-webkit-input-placeholder {color: #000;font-family: Cooper Hewitt Book;}
.form-section ::-ms-input-placeholder {color: #000;font-family: Cooper Hewitt Book;}
.header-nav nav ul .current_page_item a {color: #2b2b2b;}
.header-nav nav ul .current_page_item ul li a {color: #d44600;}
.header-nav nav ul .current_page_item ul li a:hover {color: #2b2b2b;}
.header-nav nav ul a:hover {color: #2b2b2b;}
.footer-nav ul li a:hover {color: #d44600;}
.header-logo {padding-left: 15px;padding-right: 15px;width: 17%;float: left;position: relative;}
.header-nav { margin: 20px 0;padding-left: 15px;padding-right: 15px;text-align: center;}
.header-contact {padding-left: 15px;padding-right: 15px;text-align: right;margin-top: 27px;}
header.site-header {background-color: #ffffff;left: 0;padding: 40px 0 100px 0;right: 0;top: 0;z-index: 9;}
.header-nav nav {float: left;}
.header-nav nav ul a {color: #d44600;font-family: Cooper Hewitt;font-size: 16px;font-weight: bold;text-decoration: none;text-transform: uppercase;}
.header-nav nav ul {list-style: none;margin: 0;padding: 0;float: left;}
.header-nav nav ul li {display: inline-block;padding: 0 13px;position: relative;text-align: center;vertical-align: middle;width: 122px;margin-left: 0;}
.nav-mobile {display: none;position: absolute;top: 0;right: 10px;height: 55px;width: 62px;z-index: 9;}
#nav-toggle {position: absolute;left: 18px;top: 14px;cursor: pointer;padding: 11px 35px 16px 0px;}
.menu-item-has-children .sub-menu {display: none;padding: 7px 0;}
.menu-item-has-children:hover .sub-menu {background-color: #ffffff;display: block;left: 0;position: absolute;top: 100%;z-index: 9;}
.menu-item-has-children .sub-menu li {padding: 5px 0;}

.header-contact-number {display: block;}
.mobile-call-text {display: none;}

.header-contact p {font-family: Cooper Hewitt;font-size: 13px;margin-bottom: 3px;font-weight: bold;}
.header-contact a {font-family: Cooper Hewitt;font-weight: bold;font-size: 28px;color: #d44600;line-height: 32px;}


/*--------------------------------------------------------------
# FINISH HEADER
--------------------------------------------------------------*/

.banner-section {padding: 30px 0 39px 0;position: relative; background: url(..//media/bg-new.png) no-repeat center center / cover;}


.banner-img img {margin-top: -100px;width: 100%;}

.intro p {font-size: 34px;line-height: 42px;font-family: 'Cooper Hewitt';font-weight: normal;color: #fff;}

.btn.btn-default.btn-contact {background-color: #d44600;border: medium none;border-radius: 0;color: #ffffff;float: right;font-family: "Cooper Hewitt";font-size: 20px;font-weight: bold;margin-top: 17px;max-width: 100%;padding: 15px 5px 13px;position: relative;text-align: center;text-transform: uppercase;width: 100%;}
.btn {position: relative;}
.btn:after {content: "\f054";display: inline-block;font-family: FontAwesome;font-size: 27px;font-weight: normal;margin-left: 15px;margin-top: -4px;vertical-align: middle;}

.sub-title {font-size: 32px;font-family: 'Cooper Hewitt';font-weight: bold;color: #d44600;text-transform: uppercase;line-height: 24px;}
.our-service-info {padding-bottom: 0;padding-right: 50px;padding-top: 30px;}
.our-service-info p {font-size: 16px;font-family: 'Cooper Hewitt Book';color: #000;line-height: 22px;margin-bottom: 0;margin-top: 7px;}
.our-services {position: relative;margin: 17px 0;background-position: 100% center;background-repeat: no-repeat;background-size: 50% auto;background-color: #ffffff;}
.service-content {display: table-cell;vertical-align: middle;z-index: 9;}
.right-image {display: table-cell;vertical-align: middle;float: none;}
.our-services .right-image {height: 100%;position: absolute;right: 0;top: 0;}

.right-image {background-position: center center;background-repeat: no-repeat;background-size: cover;}
.right-image img {display: block;height: auto;max-width: 100%;opacity: 0;visibility: hidden;}

.services-list {padding-right: 60px;margin-left: -10px;}
.services-list ul {padding: 0;margin-top: 30px;}
.services-list ul li {border-bottom: 1px solid #ccc;display: block;padding-left: 41px;position: relative;}
.services-list ul li:after {content: "\f054";position: absolute;font-size: 22px;font-weight: normal;position: absolute;left: 11px;top: 11px;font-family: FontAwesome;color: #282828;}
.services-list ul li a {font-size: 22px;line-height: 51px;color: #d44600;font-family: Cooper Hewitt Book;}
.services-list ul li a:hover {text-decoration: none;}
.our-services-box {background-color: #d1d1d1;display: inline-block;font-family: 'Cooper Hewitt Book';color: #000;margin-top: 17px;padding: 18px 22px 18px 24px;margin-bottom: 28px;}
.our-services-box p {}
.our-services-box .learn-more-link {color: #d44600;font-weight: bold;white-space: nowrap;}

.article-block-section {background-color: #2b2b2b;padding: 30px 0;}

.article-block-section .col-sm-4 {border-right: 1px solid rgba(255, 255, 255, 0.2);}
.article-block-section .col-sm-4:last-child {border-right: none;}
.article-block-section .col-sm-4:nth-of-type(1) .block-list {padding-right: 45px;}
.article-block-section .col-sm-4:nth-of-type(2) .block-list {padding-left: 25px;padding-right: 25px;}
.article-block-section .col-sm-4:nth-of-type(3) .block-list {padding-left: 45px;}
.block-list {text-align: center;padding-bottom: 39px;padding-top: 45px;}
.block-list .sub-title {color: #ffffff;font-size: 32px;line-height: 24px;margin-bottom: 15px;margin-top: 30px;text-transform: uppercase;}
.block-list p {font-size: 16px;color: #fff;font-family: "Cooper Hewitt Book";line-height: 22px;}
.block-list a {display: inline-block;}
.learn-more-link {position: relative;font-size: 16px;line-height: 22px;color: #fff;font-weight: bold;}
.learn-more-link:after {content: "\f054";display: inline-block;font-family: FontAwesome;font-size: 14px;font-weight: normal;margin-left: 7px;vertical-align: middle;}
.learn-more-link:hover {color: #d44600;text-decoration: none;}
.learn-more-link:focus {color: #fff;}

.logo-section {padding: 55px 0 59px 0;}
.logo-section .member-logo-border {border-left: 1px solid #a2a2a2;padding-left: 35px;}
.logo-section .sub-title {color: #d44600;}
.reviews-logo {padding: 0;margin-top: 68px;}
.reviews-logo li {display: inline-block;padding-right: 50px;margin-left: 0;}
.reviews-logo li:last-child {padding-right: 0;}
.member-logo {padding: 0;margin-top: 43px;}
.member-logo li {display: inline-block;padding-right: 45px;margin-left: 0;}
.member-logo li:last-child {padding-right: 0;}

.form-section {background-color: #1b1b1b;padding: 20px 0 43px 0;}
.form-section ul{ display: block; list-style: none; margin: 0; padding: 0;}
.form-section ul li{ float: left; padding-left: 20px;  position: relative;margin-left: 0;list-style: outside none none;min-height: 60px;}
.form-section ul li:first-child {padding-left: 15px;}
.form-section ul li:last-child{ padding:0;float: right;}
.form-section ul li:last-child {}
.form-section input {border: 1px solid rgba(0, 0, 0, 0);border-radius: 0;height: 51px;padding: 6px 10px;width: 326px;}
.form-section input[type="submit"] {background-color: #323232;color: #ffffff;font-size: 15px;font-weight: bold;text-transform: uppercase;font-family: Cooper Hewitt; width: 114px;padding-top: 9px;}
.form-section .wpcf7-recaptcha {height: 100%;left: -33px;position: absolute;top: 0;-webkit-transform: scale(0.68);transform: scale(0.68);vertical-align: top;width: 226px;}
.form-section input[type="email"] + span.wpcf7-not-valid-tip {left: 0;position: absolute;bottom: -42px;}
.form-section input[type="text"] + span.wpcf7-not-valid-tip {left: 0;position: absolute;bottom: -42px;}
.form-section input[type="tel"] + span.wpcf7-not-valid-tip {left: 0;position: absolute;bottom: -42px;}
.form-section div.wpcf7-validation-errors {font-size: 18px;font-family: "Cooper Hewitt Book";padding: 0 6px;position: relative;bottom: -30px;}
.form-section div.wpcf7-response-output {color: #ff0000;font-family: Cooper Hewitt Book;font-size: 18px;padding: 0 6px;position: relative;bottom: -16px;}
.form-section .wpcf7-form-control-wrap .wpcf7-not-valid-tip {bottom: -75px;max-width: 300px;position: absolute;width: 226px;}



.footer-section {background-color: #2b2b2b;padding: 19px 0 80px 0;}
.footer-section .small-title {margin-bottom: 6px;}
.small-title {font-size: 20px;color: #fff;font-family: "Cooper Hewitt";font-weight: bold;text-transform: uppercase;margin-bottom: 19px;}
.footer-address p {color: #fff;font-size: 14px;margin: 0;font-family: "Cooper Hewitt Book";margin-bottom: 5px;white-space: nowrap;}
.footer-address p a {color: #fff;line-height: 32px;}
.footer-address p a:hover {color: #fff;text-decoration: none;}

.footer-nav ul {padding: 0;}
.footer-nav ul li {display: inline-block;margin-left: 0;}
.footer-nav ul li:after {color: #ffffff;content: "-";display: inline-block;font-size: inherit;margin-left: 3px;margin-right: 3px;}
.footer-nav ul li:last-child::after {content: none;}
.footer-nav ul li a {color: #fff;font-size: 14px;font-family: "Cooper Hewitt Book";}
.footer-nav ul li a:hover {text-decoration: none;}
.footer-links p {color: #fff;font-family: 'Cooper Hewitt Book';}
.footer-links p a {color: #fff;}
.footer-links p a:hover {color: #fff;text-decoration: none;}
.footer-links {padding-left: 75px;}

.socket-links {padding: 0;display: inline-block;margin-top: 44px;}

.socket-links li {display: inline-block;margin-right: 5px;margin-left: 0;}
.socket-links li img {display: block;}

.socket-links li:nth-of-type(2) {background-position: -50px center;}
.socket-links li:nth-of-type(3) {background-position: -100px center;}
.socket-links li:nth-of-type(4) {background-position: -150px center;}
.footer-socials {border-right: 1px solid rgba(255, 255, 255, 0.2);padding: 0 14px 0 0;}
.socket-links.footer-payment li:nth-of-type(1) {background-position: -238px center;}
.socket-links.footer-payment li:nth-of-type(2) {background-position: -291px center;}
.footer-payment {padding: 0 0 0 15px;}

.caption {background-color: rgba(255, 255, 255, 0.5);left: 0;max-width: 934px;padding: 25px 60px 27px;position: absolute;top: 46%;}
.caption h1 {color: #d44600;font-size: 33px;font-weight: bold;font-family: Cooper Hewitt;text-transform: uppercase;margin: 0;line-height: normal;}
.caption .caption-link {color: #2b2b2b;font-size: 21px;font-weight: bold;font-family: Cooper Hewitt;position: relative;}
.caption .caption-link:after {content: "\f054";display: inline-block;font-family: FontAwesome;font-size: 24px;font-weight: normal;margin-left: 7px;vertical-align: middle;}
.caption .caption-link:hover {text-decoration: none;}
.home-slider {margin-top: -110px;margin-bottom: 61px; border: 14px solid #ffffff;}
.home-slider .item{ background-position: center; background-repeat: no-repeat; background-size: cover; display: block; }
.home-slider .owl-item img { display: block;}

.inner-content-left p:first-of-type {margin-top: 20px;}
.inner-banner-image {margin-top: -110px;background-position: center center;background-repeat: no-repeat;background-size: cover;border: 14px solid #ffffff;position: relative;}
.banner-section .inner-banner-image .caption {padding: 21px 60px 21px 45px;top: 60%;}
.inner-banner-image img {height: auto;opacity: 0;visibility: hidden;width: 100%;}
.background-white {background-color: #ffffff;display: inline-block;margin-top: 40px;padding: 25px 46px 44px 46px; width: 100%;}
.background-white p {font-size: 16px;line-height: 22px;font-family: Cooper Hewitt Book;margin-bottom: 22px;clear: both;}
.inner-content-section p { clear: none; }
.inner-title {font-size: 27px;font-family: 'Cooper Hewitt';font-weight: bold;color: #d44600;text-transform: uppercase;line-height: normal;}
.our-service-info.inner-service-list {padding: 0 0 0 62px;}
.inner-service-list .services-list li {border: none;padding-left: 20px;margin-left: 0;}
.inner-service-list .services-list li a {font-size: 16px;line-height:33px;color: #000000;}
.inner-service-list .services-list ul li::after {top: 8px;font-size: inherit;left: 0;}
.inner-service-list .services-list {padding: 0;margin-left: 0;}
.inner-service-list .services-list ul {margin-top: 0;padding: 0;}
.inner-service-list .inner-title {margin-bottom: 8px;}
.background-white h3 {color: #d44600;font-family: Cooper Hewitt Book;font-size: 22px;margin-bottom: 5px;}
.btn-green {background-color: #008000;border: 1px solid transparent;border-radius: 0;font-size: 20px;color: #fff;font-family: Cooper Hewitt;font-weight: bold;text-transform: uppercase;padding: 40px;}
.btn-danger {background-color: #d44600;border: 1px solid transparent;border-radius: 0;font-size: 17px;color: #fff;font-family: Cooper Hewitt;font-weight: bold;text-transform: uppercase;padding: 13px 5px;min-width: 413px;margin-top: 4px;}
.btn.btn-danger:after {font-size: 20px;right: 53px;top: 10px;}

.hidden-copyright p {color: #ffffff;font-family: "Cooper Hewitt Book";font-size: 11.2px;display: none;}
.hidden-copyright p a {color: #ffffff;}
.hidden-copyright p a:hover {color: #ffffff;text-decoration: none;}

.alignleft {float: left;margin-bottom: 15px;margin-right: 15px;}

.aligncenter {display: block;float: none;margin: 15px auto;}

.alignright {float: right;margin: 0 0 15px 15px;}




.header-right {display: -webkit-box;display: -ms-flexbox;display: flex;display: -webkit-flex;-webkit-box-orient: horizontal;-webkit-box-direction: reverse;-ms-flex-direction: row-reverse;flex-direction: row-reverse;flex-direction: -webkit-row-reverse;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;justify-content: -webkit-space-between;width: 83%;float: right;}




div.wpcf7-response-output{ clear: both; border:none; }
div.wpcf7-validation-errors{ border: none;  color: #ff0000;}
div.wpcf7-mail-sent-ok{border: none; color: #398f14 !important;}

.inner-content-left .wpcf7-form label {width: 100%;}
.inner-content-left .wpcf7-form input {width: 100%;}
.inner-content-left .wpcf7-form textarea {width: 100%;}
.inner-content-left .wpcf7-form input[type="submit"] {background-color: #323232; border-radius: 0; border: 1px solid transparent; color: #ffffff; font-size: 20px; font-weight: bold; font-family: Cooper Hewitt; padding: 10px;
text-transform: uppercase; width: 114px;}
